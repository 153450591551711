/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import { useState } from 'react';
import Down from '../../icons/Down.svg?react';
import Up from '../../icons/Up.svg?react';
import Layer from '../../icons/Layer.svg?react';
import Typography from '@mui/material/Typography';
import LayerGroup from './LayerGroup';
import { useSelector } from 'react-redux';
import { IState } from '../../reducers';
import { ILayerGroup } from '../../reducers/legend';
import { useIntl } from 'react-intl';
import mikeSharedTheme, { mikePalette } from '../../styles/mikeSharedTheme';


const WrapperStyle = css`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index:9999;
  background-color: ${mikePalette.background.paper};   
`;

const HeaderStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TitleAndButtonStyle = css`
  display: flex;
  align-items: center;
`;

const TitleStyle = css`
  padding-left: ${mikeSharedTheme.spacing(1)};
`;

export const ViewerLegend = () => {
  const intl = useIntl();
  const layerGroups = useSelector(
    (state: IState) => state.legend.layerGroups
  ); 
  const [open, setOpen] = useState(false);
 
  return (    
     <div css={WrapperStyle}>     
        <div css={HeaderStyle}>
          <div css={TitleAndButtonStyle}>
            <Layer/>
            <Typography css= {TitleStyle} variant="body2"><b>{intl.formatMessage({id: 'viewerLegend.layers'})}</b></Typography>
          </div>          
          {open ? <Up onClick={() => setOpen(!open)}/> : <Down  onClick={() => setOpen(!open)}/>}
        </div> 
        {open && <div>
          {layerGroups.map((l: ILayerGroup) => <LayerGroup  key={l.title} layerGroup={l}/>)}
        </div>}  
    </div>  
  );
};

export default ViewerLegend