/* eslint-disable @typescript-eslint/no-unsafe-return */
import { IViewerBounds } from "../MikeVisualizer/lib/IMikeVisualizerModels"
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import { IThreeDRenderElement } from '../MikeVisualizer/lib/IMikeVisualizerModels';
import { IFastWaveConfig, IJob } from "../model/IFastWaveConfig"
import { IProvider } from "../model/IGetDataLinkMeshStatus"
import { IGetDataset } from "../model/IGetDataset"
import { CONFIG, DATASETS, EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_ARCHIVE_FIXED_NAME_PART, EXTRACTION_INFO_FILE_EXTENSION,   EXTRACTION_INFO_FIXED_NAME_PART,   FASTWAVE,   FASTWAVECONFIG, FILE, GISVECTORDATA, LAYERCONFIG, OUTLINE, TESTRUN_OUTPUT } from "../shared/constants"
import { layerOrder } from "../components/Viewer";

export const initialBounds = [-40, 40, -70, 70, 0, 0] as IViewerBounds;

export const orderLayers = (layerId?: string) => {
  const moveLayerToTopLayer = (elementId: string) => {
    const { getState } = MikeVisualizerLib;
    const { renderer, renderWindow, renderedElements } = getState();
    const ids = renderedElements.map((re: IThreeDRenderElement) => re.id) 
    if (ids.includes(elementId)){
      renderer.moveEnhancedActorToTop(elementId);
      renderWindow.render();
    }  
  } 
  layerOrder.forEach((id: string) => moveLayerToTopLayer(id));  
  if (layerId){
    moveLayerToTopLayer(layerId);
  }
}

export const getLayerOrderIndex = (elementId: string) => {
  const index = layerOrder.findIndex((layer: string) => layer === elementId)
  return index > -1 ? index : layerOrder.length;
}

export const getFastwaveConfigFile = (datasets: Array<IGetDataset>) => {
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === FASTWAVECONFIG })
}

export const getLayerConfigFile = (datasets: Array<IGetDataset>) => {
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === LAYERCONFIG })
}

export const getJobsConfigFile  = (datasets: Array<IGetDataset>) => {
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === DATASETS.JOBS_CONFIG })
}

export const getFastwaveConfigFiles = (datasets: Array<IGetDataset>) => {
  return datasets.filter((ds: IGetDataset) => { return ds.datasetType === FILE && ds.name.startsWith(FASTWAVE) && ds.name.endsWith(CONFIG) })
}

export const getDrawnOutlineFiles = (datasets: Array<IGetDataset>) => { 
  const ds = datasets.filter((ds: IGetDataset) => { return ds.datasetType === GISVECTORDATA && ds.name.startsWith(OUTLINE) && ds.properties && ds.properties[FASTWAVE.toUpperCase()] && ds.properties[FASTWAVE.toUpperCase()] === OUTLINE})
  return ds
}

export const getJob = (jobs: Array<IJob>, jobIndex: string) => {
  if (jobs && jobs.length > 0){
    return jobs.find((job: IJob) => job.rowKey === jobIndex)
  }
  return undefined
}

export const getDataLinkOverlappingPeriod = (relevantProviders: Array<IProvider>) => { 
  const fromDates = relevantProviders.map(p => p.from).sort()
  const toDates = relevantProviders.map(p => p.to).sort()
  if (fromDates && fromDates.length > 0 && toDates && toDates.length > 0){
    const startDate = fromDates[fromDates.length -1] + "T00:00:00"
    const endDate = toDates[0] + "T00:00:00"
    return {startDate, endDate}
  }
}

export const getMeshId = (fastWaveConfig: IFastWaveConfig) => {
  if (fastWaveConfig && fastWaveConfig.mesh_file && fastWaveConfig.mesh_file.dataset_id){
    return fastWaveConfig.mesh_file.dataset_id
  }
  return null
}

export const getFastwaveTestRunOutput = (datasets: Array<IGetDataset>) => { 
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === TESTRUN_OUTPUT })
}

export const getDataLinkExtractionInfo = (datasets: Array<IGetDataset>, name: string) => { 
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === name && ds.name.endsWith(EXTRACTION_INFO_FILE_EXTENSION) })
}

export const getDataLinkExtractionArchive = (datasets: Array<IGetDataset>, name: string) => { 
  return datasets.find((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name === name && ds.name.endsWith(EXTRACTION_ARCHIVE_EXTENSION) })
}

export const getRelatedDataLinkExtractionInfo = (datasets: Array<IGetDataset>, zipFileName: string) => {
  if (zipFileName){
    const fullFileNameParts = zipFileName.split(".")
    if (fullFileNameParts.length > 0){
      const nameParts = fullFileNameParts[0].split(" ")
      if (nameParts.length === 2){
        const infoFileName = DATASETS.EXTRACTION_INFO + " " + nameParts[1] + "." + EXTRACTION_INFO_FILE_EXTENSION      
        return getDataLinkExtractionInfo(datasets, infoFileName) 
      }
    } 
  } 
}

export const getBoundsTooHuge = (epsgCode: number, bounds: IViewerBounds) => {
   return epsgCode === 4326 && (bounds[0] < initialBounds[0] || bounds[1] > initialBounds[1] || bounds[2] < initialBounds[2] || bounds[3] > initialBounds[3])   
}

export const getLatestDataLinkExtractionArchive = (datasets: Array<IGetDataset>) => { 
  const extractions = datasets.filter((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name.startsWith(EXTRACTION_ARCHIVE_FIXED_NAME_PART) && ds.name.endsWith(EXTRACTION_ARCHIVE_EXTENSION) })
  if (extractions.length === 0){
    return null
  }
  else if (extractions.length === 1){
    return extractions[0]
  }
  else{
    const latestExtraction = extractions.reduce((a: IGetDataset, b: IGetDataset) => (a.createdAt > b.createdAt ? a : b));
    return latestExtraction
  }
}

export const getValidExtractions = (datasets: Array<IGetDataset>) => {
  const extractions = datasets.filter((ds: IGetDataset) => { return ds.datasetFormat === FILE && ds.name.startsWith(EXTRACTION_ARCHIVE_FIXED_NAME_PART) && ds.name.endsWith(EXTRACTION_ARCHIVE_EXTENSION) });
  const extractionsWthInfoFile = extractions.filter((ds: IGetDataset) => {
    const infoName = ds.name.replace(EXTRACTION_ARCHIVE_FIXED_NAME_PART, EXTRACTION_INFO_FIXED_NAME_PART);
    const infoFileName = infoName.replace(EXTRACTION_ARCHIVE_EXTENSION, EXTRACTION_INFO_FILE_EXTENSION);
    const infoDataset =  datasets.find((ds: IGetDataset) => ds.name === infoFileName);
    return infoDataset !== undefined
  })
  return extractionsWthInfoFile;
}

export const getNextFreeNumber = (existingPoints) => {
  const ids = existingPoints.map(feature => feature.id)
  let num = ids.length + 1;
  const name = 'Point';
  while (ids.some((label: string) => label === name + num)) {
    num = num + 1;
  }
  return num.toString();
}