/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'
import {  useEffect, useState } from 'react';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import MikeVisualizerUtil from '../../MikeVisualizer/lib/MikeVisualizerUtil';
import { MESH_LAYER_ID } from '../../reducers/legend';
import { toRoundedNumber } from '../../helpers/utils';
import mikeSharedTheme from '../../styles/mikeSharedTheme';

export const CellInfoZIndex = 90;

const {
  onPointIndexChanged,
  setCellIndexActorIds,
  enableMouseMovePointIdPicker,
  setPointIndexActorIds,
} = MikeVisualizerLib;

const CellInfoStyle = css`
  position: absolute;
  z-index: ${CellInfoZIndex};
  border: 1px black solid;
  background-color: ${mikeSharedTheme.palette.lightGrey?.main};
  padding: ${mikeSharedTheme.spacing(0.5)};
  color: ${mikeSharedTheme.palette.text.primary};
`;

interface IValAndCoords {
  x: number;
  y: number;
  val: string;
  coordinates: Array<number>;
}

const CellInfo = () => {

  const [valAndCoords, setValAndCoords] = useState<IValAndCoords | null>(null)

  useEffect(
    () => {
      const attribute = "mmg_Elevation"
      setPointIndexActorIds([MESH_LAYER_ID]);
      setCellIndexActorIds([]);
      enableMouseMovePointIdPicker(0.001);
      const handlePointIndexChanged = (
        (pointIndexChanged) => {
          let valueAndXY;
         
          if (pointIndexChanged.index !== -1) {  
            const values = MikeVisualizerUtil.getDataValues(pointIndexChanged.actorId, attribute);
            if (values && values.length > pointIndexChanged.index) {
              const val = toRoundedNumber(values[pointIndexChanged.index], 2) 
              const res = val.toString();
              valueAndXY = {val: res, x: pointIndexChanged.displayCoordinates.x, y: pointIndexChanged.displayCoordinates.y, coordinates: pointIndexChanged.coordinates  };
            }
          }
          setValAndCoords(valueAndXY)
        })
       
      const events = [onPointIndexChanged(handlePointIndexChanged)];

      return () => {
        setCellIndexActorIds([]);
        setPointIndexActorIds([]);
        events.forEach((unsubscribe) => unsubscribe());
      };
    },[]);

  const getVal = () => {
/*     let v = ""
    if (valAndCoords && valAndCoords.coordinates && valAndCoords.coordinates.length > 0){
      v = "px: " + valAndCoords.x + " py: " + valAndCoords.y +   " x: " +  toRoundedNumber(valAndCoords.coordinates[0], 2) + " y: " +  toRoundedNumber(valAndCoords.coordinates[1], 2) + " z: " +  valAndCoords.val
    } */
    return valAndCoords?.val
  }  

  return (
    <>
      {valAndCoords && valAndCoords.coordinates && valAndCoords.coordinates.length > 0 &&
        (
          <div
            css={CellInfoStyle}
            style={{
              bottom: `${valAndCoords.y + 8}px`,
              left: `${valAndCoords.x + 808}px`,
            }}
          >  
            {getVal()}
          </div>
        )}
    </>
  );
};

export default CellInfo;
