/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable react-refresh/only-export-components */
import { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import MikeVisualizerLib from '../../MikeVisualizer/lib/MikeVisualizer';
import { MikeVisualizer } from '../../MikeVisualizer';
import { IViewerBounds } from '../../MikeVisualizer/lib/IMikeVisualizerModels';
import {
  ViewerWrapper 
} from './ViewerWrapper';
import {
  getBaseMapConfiguration,
  EBaseMapIds,
} from '../../MikeVisualizer/MikeBaseMapConfigurations';
import { FeatureCollection } from 'geojson';
import { drawnDataAdd, setDrawing, setMapReady } from '../../actions/mapContent'
import ViewerTools from '../ViewerTools/ViewerTools';
import { useMatch } from 'react-router-dom';
import { IState } from '../../reducers';
import { addError } from '../../actions/errors';
import { DRAWING } from '../../shared/constants';
import { ViewerFooter } from './ViewerFooter';
import ViewerLegend from '../ViewerLegend/ViewerLegend';
import { initialBounds } from '../../helpers/fastwave';
import { MESH_LAYER_ID } from '../../reducers/legend';

const {   
  onBaseMapProjectionFetchFailed,
  onBaseMapChanged,
  onBaseMapDestroyed,   
  setupMapAttributionControl,
  onMouseMove,
} = MikeVisualizerLib;

export const pinSvg = `
  <svg width="17" height="24" viewBox="0 0 17 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd" d="M2.20671 8.3432C2.39563 10.86 3.30181 12.0575 4.78883 13.9397C4.80817 13.9642 4.82758 13.9888 4.84705 14.0134C5.94028 15.3969 7.24006 17.0418 8.49924 19.5663C9.75838 17.1732 10.9787 15.5771 11.9951 14.2478C12.0687 14.1516 12.1412 14.0568 12.2125 13.9632C13.6202 12.1167 14.4601 10.9007 14.6507 8.34341C14.6216 4.57499 11.6885 1.71429 8.42871 1.71429C5.16901 1.71429 2.23592 4.57489 2.20671 8.3432ZM16.4287 8.4C16.2021 11.6346 15.0251 13.1738 13.427 15.2638C12.254 16.798 10.854 18.6289 9.43647 21.6449C9.10061 22.3594 8.76376 23.1405 8.42871 24C8.1292 23.1293 7.82046 22.3389 7.50642 21.6168C6.14349 18.4824 4.68067 16.6313 3.43814 15.0589C1.86687 13.0705 0.647888 11.528 0.428711 8.4C0.428711 3.8 4.02871 0 8.42871 0C12.8287 0 16.4287 3.8 16.4287 8.4Z" fill="rgb(9,51,75)"/>
  <path d="M11.9843 7.71429C11.9843 9.60783 10.3924 11.1429 8.42871 11.1429C6.46503 11.1429 4.87316 9.60783 4.87316 7.71429C4.87316 5.82074 6.46503 4.28571 8.42871 4.28571C10.3924 4.28571 11.9843 5.82074 11.9843 7.71429Z" fill="rgb(9,51,75)"/>
  </svg>
`;

export const GWM_ID = 'gwm_id'

export const DOMAIN_ID = 'domain_id';

export const AOI_ID = 'aoi_id';

export const ISLANDS_ID = 'island_id';

export const COASTLINE_ID = 'coastline_id';

export const GEBCO_ID = 'gebco_id';

export const SHORELINE_ID = 'shoreline_id';

export const AUTO_MESH_ID = 'autoMesh';

export const OWN_SHORELINE_ID = 'ownShoreline_id';

export const OWN_SHORELINE = 'Own shoreline';

export const MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID = 'centralpoints';

export const layerOrder = [MESH_LAYER_ID, AUTO_MESH_ID, OWN_SHORELINE_ID, AOI_ID, DOMAIN_ID, ISLANDS_ID];

export const ELEVATION = 'elevation'
export const MMG_ELEVATION = 'mmg_Elevation'
export const POINT_Z = 'Point-Z'

const { 
  onDrawnDataUpdated,
  setCrs,      
  setViewModeTo2D,  
  setViewerZScale
} = MikeVisualizerLib;

const Viewer = () => { 
  const dispatch = useDispatch(); 
  
  const matchPoints = useMatch('/project/:id/points')   

  const mapIsReady: boolean = useSelector(
    (state: IState) => state.mapContent.mapIsReady
  );  

  const proj4String = useSelector(
    (state: IState) => state.mapContent.proj4String
  );   

  const epsg = useSelector(
    (state: IState) => state.mapContent.epsgCode
  ); 

  const workspaceBoundingBox = useSelector(
    (state: IState) => state.mapContent.meshExtent
  ); 

  const [currentViewerCoordinates, setCurrentViewerCoordinates] = useState([0, 0, 0]);

  const basemapNotSupportedToastOnce = useRef(false);
  const MAP_ATTRIBUTION_CONTAINER_ID = 'MAP_ATTRIBUTION';  
  const { url, attributions } = getBaseMapConfiguration(EBaseMapIds.OSM);
 
 useEffect(() => {
  const { resetCameraToBounds, updateViewer2DLayers } = MikeVisualizerLib;
  const getBoundingBox = (bounds: Array<number>) => {
    return bounds && bounds.length > 3 // bounds needs to include at least 4 values (xMin, xMax, yMin, yMax)
      ? [...bounds.slice(0, 4), 0, 0] // Z bounds are set to 0, because they seem to conflict with open layers' zoom. Zooming is done instead by centering workspace [xmin, ymin, xmax, ymax] coordinates, so zmin & zmax are not relevant & might clash with open layers zoom logic. Worth investgating if this can be prevented as part of MikeVisualizer, although it might make sense to set z bounds for 'pure' 3D visualizations :/
      : null;
  };
  if (mapIsReady && workspaceBoundingBox){
    const bounds = getBoundingBox(workspaceBoundingBox)  
    resetCameraToBounds(bounds as IViewerBounds) 
    updateViewer2DLayers();
  }
  
}, [workspaceBoundingBox, mapIsReady]); 

  const getAttributionControlTarget = () => {
    return document.getElementById(MAP_ATTRIBUTION_CONTAINER_ID);
  };

  const setupMap = useCallback(async () => {    
    setCrs(proj4String, epsg);
    try{
      await setViewModeTo2D(url, attributions ? attributions : []) ;     
      setViewerZScale(1e-10);     
      dispatch(setMapReady())
    }
    catch (error){
      console.log(error)
    }  

  },[attributions, dispatch, proj4String, url, epsg])

    
  useEffect(() => {
    if (matchPoints){     
      dispatch(setDrawing())
    }
    else{
      dispatch(setDrawing(DRAWING.NONE))
    }
  }, [matchPoints, mapIsReady, dispatch]);

  
 useEffect(() => {
  const handleBaseMapFailed = () => {
    if (!basemapNotSupportedToastOnce){
      dispatch(addError("Failed to load basemap for selected projection system"))
      basemapNotSupportedToastOnce.current = true
    }      
  }

  const setupAttributionControl = () => {
    const target = getAttributionControlTarget();
    target && setupMapAttributionControl(target);
    return;
  };
  
  const clearAttributionControl = () => {
    const target = getAttributionControlTarget();
  
    if (target) {
      target.innerHTML = '';
    }
    return target;
  };
  const onViewerMouseOver = (coordinates: Array<number>) => {
    setCurrentViewerCoordinates(coordinates);
  };

  const drawUpdateCallback =(data: FeatureCollection<any, any>) => {
    if (data && data.features && data.features.length > 0){       
       dispatch(drawnDataAdd(data.features)); 
     }  
  }

  const events = [
    onDrawnDataUpdated(drawUpdateCallback),
    onMouseMove(onViewerMouseOver),
    onBaseMapProjectionFetchFailed(handleBaseMapFailed), 
    onBaseMapChanged(() => {
      setupAttributionControl()
    }),
    onBaseMapDestroyed(() => {     
      clearAttributionControl()
    }),
  ];

  return () => {
    events.forEach((unsubscribe) => unsubscribe());
  };
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);
 
return (   
  <ViewerWrapper>
    <MikeVisualizer onReady={setupMap} bounds={epsg === 4326 ? initialBounds : workspaceBoundingBox} />
    <ViewerTools/>
    <ViewerLegend/>
    <ViewerFooter currentViewerCoordinates={currentViewerCoordinates}/>
  </ViewerWrapper> 
  ); 
}

export default Viewer
