import { OWN_SHORELINE } from '../components/Viewer'

export const DEFAULT_SELECTED_NODES = 100
export const MIN_SELECTED_NODES = 5
export const MAX_SELECTED_NODES = 2000
export const MIN_RESULTING_DIMENSIONS = 4
export const MAX_RESULTING_DIMENSIONS = 100
export const DEFAULT_RESULTING_DIMENSIONS = 10
export const MIN_MESH_RESOLUION = 15
export const DEFAULT_MESH_RESOLUION = 100
export const MAX_MESH_RESOLUION= 2000

export const DELAY = 1500
export const GET_JOB_INFO_DELAY = 1000
export const GET_WORKSPACE_INFO_DELAY = 3000

export const JOB_INFO_REMOVE_ON_DONE_DELAY = 3000
export const SCENARIO_OFFSHORE = 'Offshore'
export const SCENARIO_COASTAL = 'Coastal'
export const EVENTS = 'events'
export const JOBSCONFIG = 'jobs.config'
export const OUTPUTFOLDER = 'output'
export const BACKGROUNDFOLDER = 'background'
export const BACKGROUNDFOLDER_DESC = 'Background layers'
export const OUTPUTFILENAME = 'output'
export const CSVEXTENSION = '.csv'
export const DFS0EXTESION = '.dfs0'
export const OUTPUTFILES = [OUTPUTFILENAME + CSVEXTENSION, OUTPUTFILENAME + DFS0EXTESION]
export const OUTPUTLOG = 'Extraction-log userdefined.log'
export const FASTWAVE = 'fastwave'
export const LAYER = 'layer'
export const CONFIG = 'config'
export const JSONEXTENSION = '.json'
export const EVENTSCONFIG = EVENTS + JSONEXTENSION
export const FASTWAVECONFIG = FASTWAVE + '.' + CONFIG
export const LAYERCONFIG = LAYER + '.' + CONFIG
export const POINTS = 'points'
export const CRS = "GEOGCS['GCS_WGS_1984',DATUM['D_WGS_1984',SPHEROID['WGS_1984',6378137.0,298.257223563]],PRIMEM['Greenwich',0.0],UNIT['Degree',0.0174532925199433]]"
export const EPSG = 4326
export const DEFAULT_NOTIFY_TIMEOUT = 3500
export const GET_EXPORT_INFO_DELAY = 5000
export const EXPORT_REMOVE_ON_DONE_DELAY = 3000
export const MESH = 'mesh'
export const ISLANDS = 'islands'
export const OUTLINE = 'domain'
export const OWNDATA = 'ownData'
export const BATHYMETRY = 'bathymetry'
export const AREAOFINTEREST = 'aoi'
export const SHORELINE = 'shoreline'
export const OWNSHORELINE = 'ownshoreline'
// export const NOAASHORELINE = 'Coastline (NOAA)'
export const DOTMESH_FROM_MB = 'FWE-Mesh.mesh'
export const FLIPLAND = 'flipland'
export const SHAPEFILE = 'ESRI Shapefile'
export const GEOJSON = 'GeoJSON'
export const FILE = 'File'
export const GISVECTORDATA = 'gisvectordata'
export const SETUP = 'Setup'
export const TESTRUN_OUTPUT = 'test_output.csv'
export const ENVIRONMENTALDATA = 'environmentaldata'
export const EXTRACTION_MODEL_TYPE_COLUMN = 'Model type'
export const EXTRACTION_START_COLUMN = 'Extraction Start date'
export const EXTRACTION_END_COLUMN = 'Extraction End date'
export const ZIPFILE_NAME_COLUMN = 'Archive Zip-file name'
export const EXTRACTION_INFO_FILE_EXTENSION = 'txt'
export const EXTRACTION_ARCHIVE_EXTENSION = 'zip'
export const EXTRACTION_ARCHIVE_FIXED_NAME_PART = 'Extraction '
export const EXTRACTION_INFO_FIXED_NAME_PART = 'Extraction-info '
export const OWN_DATA_TEMPLATE = 'Template_FWE_userdata.csv'
export const OWN_DATA_TEMPLATE_DOWNLOAD_NAME = 'Template_MIKE_MS_userdata.csv'

export const MDSTENANT = 'MOOD'
export const MOODSUBSCRIPTION = 'MOODSubscription'
export const MOOD = [MDSTENANT, MOODSUBSCRIPTION]
export const WIND = 'wind'
export const WATERLEVELS = 'waterlevels'
export const WAVES = 'waves'
export const FORCINGS = [WIND, WATERLEVELS]
export const DL_WIND_ITEM = 'Wind velocity components (10 m)'
export const DL_WATERLEVEL_ITEM = 'Water Level'
export const DL_WAVE_PROVIDER = 'DHI GWM 2022'
export const DL_WIND_PROVIDER = 'ERA5'
export const DL_WATERLEVEL_PROVIDER = 'DTU 10 Tide'
export const RELEVANT_DL_PROVIDERS = [DL_WAVE_PROVIDER, DL_WIND_PROVIDER, DL_WATERLEVEL_PROVIDER]
export const PROVIDERS_WITH_LIMITED_AVAILABILITY = [DL_WAVE_PROVIDER, DL_WIND_PROVIDER]
export const PROVIDER = 'extraction'
export const UPLOAD = 'upload'
export const PREVIOUS = 'previous'
export const BOUNDARY_CONDITION_SOURCE = 'boundary_condition_source'
export const BACKGROUND = 'background'

export const BOUNDARY_CONDITION_SOURCES = {
  PROVIDER: PROVIDER,
  UPLOAD: UPLOAD,
  PREVIOUS: PREVIOUS
}

export const JOBS = {
  ENVIRONMENTALDATA: 'environmentaldata',
  MDA: 'MDA',
  TESTSETUP: 'TestSetup',
  AUTOSETUP: 'AutoSetup'
}

export const CANCELABLE_JOBS = [JOBS.TESTSETUP, JOBS.AUTOSETUP]

export const DATALINKJOBS = {
  BOUNDARYEXTRACTION: 'BoundaryExtraction',
  DATAEXTRACTION: 'DataExtraction',
  MESHTILING: 'MeshTiling',
  MESHTILINGORIGINALPROJECTION: 'MeshTilingOriginalProjection'
}

export const DATASETS = {
  EVENTS: EVENTS,
  CONFIG: CONFIG,
  POINTS: POINTS,
  MESHBOUNDARY: 'Mesh Boundary',
  BOUNDARYCONDITION: 'Boundary Conditions',
  TESTRUNOUTPUT: 'Test run output',
  EXTRACTION_INFO: 'Extraction-info',
  JOBS_CONFIG: JOBSCONFIG,
  SETUP: SETUP,
  OUTLINE: OUTLINE,
  AREAOFINTEREST: AREAOFINTEREST,
  ISLANDS: ISLANDS,
  BATHYMETRY: BATHYMETRY,
  OWNDATA: OWNDATA,
  OWN_DATA_TEMPLATE: OWN_DATA_TEMPLATE,
  // SHORELINE: SHORELINE,
  OWN_SHORELINE: OWN_SHORELINE,
  TRANFORM_USER_DATA_LOG: 'Tranform log',
  RESULTS: 'Results',
  DOTMESH: 'dotMesh',
  BACKGROUND: BACKGROUND,
  LAYER_CONFIG: LAYERCONFIG,
}

export const IMPORTS = {
  CONFIG: CONFIG,
  JOBS_CONFIG: JOBSCONFIG,
  LAYER_CONFIG: LAYERCONFIG,
  MESH: MESH,
  SETUP: SETUP,
  TESTRUN_TIMESTEP: 'Test run timestep',
  OUTLINE: OUTLINE,
  AREAOFINTEREST: AREAOFINTEREST,
  BATHYMETRY: BATHYMETRY,
  OWNDATA: OWNDATA,
  OWN_SHORELINE: OWN_SHORELINE,
  ISLANDS: ISLANDS,
  BACKGROUND: BACKGROUND
}

export const DRAWING = {
  MESHOUTLINE: OUTLINE,
  AREAOFINTEREST: AREAOFINTEREST,
  POINT: POINTS,
  OWN_SHORELINE: OWN_SHORELINE,
  ISLANDS: ISLANDS,
  NONE: 'none'  
}

export enum READER {
  XY = 'xy',
  ZIPPEDSHAPE = 'zippedShape',
  BATHYMETRY ='bathymetry'
}

export enum MESHTYPE {
  UPLOAD = 'upload',
  AUTOMESH = 'automesh'
}

export const ENVIRONMENTALDATATYPE = {
  UPLOAD: 'upload',
  PROVIDER: 'provider',
  PREVIOUS: 'previous',
}

export const OWNDATAHINTS = {
  HOWTO: 'howToUseOption',
  LINK: 'link',
  UPLOADBUTTON: 'uploadButton'
}
