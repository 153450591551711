/* eslint-disable @typescript-eslint/no-floating-promises */
import ActionType from "../actions/ActionType";
import { IAction } from "../actions/Action";
import { FeatureCollection, Point } from 'geojson';
import { IRenderElement, IThreeDRenderElement, IViewerBounds } from '../MikeVisualizer/lib/IMikeVisualizerModels';
import { Feature } from "geojson";
import { AUTO_MESH_ID, MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID, pinSvg } from "../components/Viewer";
import MikeVisualizerLib from '../MikeVisualizer/lib/MikeVisualizer';
import { IGetDataset } from "../model/IGetDataset";
import { IFastWaveConfig } from "../model/IFastWaveConfig";
import { csvToTable } from "../helpers/csv";
import { Circle, Fill, Stroke, Style, Icon, Text } from 'ol/style'; 
import { boundaryColor } from "../helpers/colors";
import { IAxis, IChartItem, IInitialChartData } from "../model/IInitialChartData";
import { IChartBullet, IChartData } from "../model/IChartData";
import { parseToTime } from "../helpers/fixTime";
import { ICollapsedItem, IDisplayProvider, IExtractionBuilderData, IProvider } from "../model/IGetDataLinkMeshStatus";
import { getDataLinkOverlappingPeriod, initialBounds } from "../helpers/fastwave";

import { ISetup } from "../model/ISetup";
import IProjection from "../components/mike-projection-select/model/IProjection";
import { DL_WATERLEVEL_ITEM, DL_WATERLEVEL_PROVIDER, DL_WAVE_PROVIDER, DL_WIND_ITEM, DL_WIND_PROVIDER, DRAWING, MOOD, READER, WATERLEVELS, WAVES, WIND } from "../shared/constants";
import { enable2DPointDrawing, enable2DPolylineDrawingV2 } from "../MikeVisualizer/lib/2d/draw/MikeVisualizer2DDrawingTools";
import { MESH_BOUNDARY_LAYER_ID, MESH_LAYER_ID, POINT_LAYER_ID } from "./legend";
import { toFeaturesWithRoundedCoords } from "../helpers/utils";

import { IUploadedPoint } from "../apis/backendApi";
import { IColumn } from "../components/TableComponents/support";
import { intl } from "../main";
import MIKE_COLORS from "../styles/mike-colors";
import { FONTFAMILY } from "../styles/mikeSharedTheme";

const LABELFONTSIZE = '0.8rem';

export  const pinStyle = new Style({ 
  image: new Icon({
    // NB: this does not seem to work with imported svgs. They need to be inlined as strings.
    src: `data:image/svg+xml;utf8,${pinSvg}`,
  }),
});

export interface IUploadedGeomtry {
  geojson: FeatureCollection;
  elementId: string;
  bounds: IViewerBounds
}

export interface ITableFormat {
  columns: Array<IColumn>;
  rows: [];
}

export interface IListItem {
  id: string;
  displayName: string;
  color: string;
  source: string;
}

export interface IDataLinkProvider {
  id: string;
  name: string;
  providers: Array<IDisplayProvider>;
  selected: IDisplayProvider | null;
} 

export interface IMapContentState {
  elevationName: string;
  proj4String: string;
  epsgCode: number;
  chartAxisRange: any;
  boundaryAndForcings: Array<IListItem>;
  boundaryConditionDataset: IGetDataset | null; 
  chartBullets: Array<IChartBullet>;
  chartData: IChartData | null;
  coordinateSystems: Array<IProjection>;
  dataLinkExtractionBuilderData: IExtractionBuilderData | null;
  dataLinkOverlappingPeriod: Array<string>;
  events: Array<string>;
  existingPointNames: Array<string>; 
  extractionInfoDataset: IGetDataset | null; 
  extractionPeriodStart: string;
  extractionPeriodEnd: string;
  fastWaveConfig: IFastWaveConfig | null;
  initialChartData: IInitialChartData | null;
  initialPointsGeojson: FeatureCollection | null;
  initialPointsGeojsonHandled: boolean;  
  loadingCentralPoints: boolean;  
  loadingChartData: boolean;
  loadingConfig: boolean;
  loadingCoordinateSystems: boolean;
  loadingDataLinkMesh: boolean;
  loadingDataLinkExtractionBuilderData: boolean;
  loadingMesh: boolean;
  loadingPointZValues: boolean;
  loadingSetups: boolean;
  loadingTestRunOutput: boolean;  
  mapIsReady: boolean; 
  mesh: string;
  meshExtent: IViewerBounds;
  pointDatasetSelectedFromPlatform: IGetDataset | null;
  points: Array<Feature<Point>>;   
  projectionSystemDialogForReaderType: READER;
  selectedBoundaryOrForcing: IListItem | null;
  selectedChartXItem: IChartItem | null;
  selectedChartYItem: IChartItem | null;
  selectedEvents: Array<string>;
  selectedMesh: IGetDataset | null;
/*   meshIsWithinGWMErrors: Array<string>;
  meshIsWithinGWMChecked: boolean; */
  gwm: string;
  selectedProjectionSystems: Array<IProjection>;
  searchProjectionSystemsById: boolean;
  setups: Array<ISetup>;
  showProjectionSystemDialog: boolean;
  showSuccessDialog: boolean;
  showTestRunDialog: boolean;  
  testRunOutput: ITableFormat | null;  
  totalExplainedVarianceRatio: number;
  uploadingPoints: boolean;
  uploadedPoints: Array<IUploadedPoint>;
  uploadPointsUrl: string;
  drawing: string;   
  autoMeshOutline: FeatureCollection | null;
  autoMeshOutlineDataset: IGetDataset | null; 
  pointDecimals: number;
  dataLinkProviders: Array<IDataLinkProvider>;
  previousExtractions: Array<IGetDataset>;
  loadingPreviousExtractions: boolean;
}

const { update2DData, enable2DPolygonDrawing, disableAllDrawingTools, delete2DData, deleteData, getState } = MikeVisualizerLib

const initState = {
  elevationName: "",
  proj4String: "",
  epsgCode: null,
  chartAxisRange: null,
  boundaryAndForcings: Array<IListItem>(),
  boundaryConditionDataset: null,
  chartBullets: Array<IChartBullet>(),
  chartData: null,
  coordinateSystems: Array<IProjection>(),
  dataLinkExtractionBuilderData: null,
  dataLinkOverlappingPeriod: Array<string>(),
  events: Array<string>(),  
  existingPointNames: Array<string>(), 
  extractionInfoDataset: null,
  extractionPeriodStart: "",
  extractionPeriodEnd: "",
  fastWaveConfig: {},
  initialChartData: null,
  initialPointsGeojson: null,
  initialPointsGeojsonHandled: false,  
  loadingChartData: false,
  loadingCentralPoints: false,
  loadingConfig: false, 
  loadingCoordinateSystems: false,
  loadingMesh: false, 
  loadingDataLinkMesh: false,
  loadingDataLinkExtractionBuilderData: false,
  loadingPointZValues: false,
  loadingSetups: false,
  loadingTestRunOutput: false,
  mapIsReady: false,
  mesh: "",
  meshExtent: null,
  points: Array<Feature<Point>>(),    
  pointDatasetSelectedFromPlatform: null,
  projectionSystemDialogForReaderType: READER.XY,
  selectedBoundaryOrForcing: null,
  selectedChartXItem: null,
  selectedChartYItem: null,
  selectedEvents: Array<string>(),  
  selectedMesh: null,
/*   meshIsWithinGWMErrors: new Array<string>(),  
  meshIsWithinGWMChecked: false, */
  gwm: "",
  selectedProjectionSystems: Array<IProjection>(),
  searchProjectionSystemsById: false,
  setups: Array<ISetup>(),
  showProjectionSystemDialog: false,
  showSuccessDialog: false, 
  showTestRunDialog: false,  
  testRunOutput: null, //csvToTable(testrunResult),
  totalExplainedVarianceRatio: 0,
  uploadingPoints: false,
  uploadedPoints: Array<IUploadedPoint>(),
  uploadPointsUrl: "",
  drawing: DRAWING.NONE,
  autoMeshOutline: null,
  autoMeshOutlineDataset: null,
  pointDecimals: 6,
  dataLinkProviders: Array<IDataLinkProvider>(),
  previousExtractions: Array<IGetDataset>(),
  loadingPreviousExtractions: false,
}

const getBoundaryStyle = () => {
  const boundaryStyle = (feature) => {    
    const color = feature && feature.values_ && feature.values_.code ? boundaryColor(feature.values_.code) : MIKE_COLORS.BRANDBLUE_DEFAULT
    return new Style({
      stroke: new Stroke({
        color: color,
        width: 3
      }),
      fill: new Fill({
        color: color
      })
    });
  };
  return boundaryStyle;
};

export const getPointStyle = () => {
  const pointStyle = (feature) => {    
    const text = feature && feature.id_ ? feature.id_ : "" // feature && feature.values_ && feature.values_.id ? feature.values_.id : ""
    return [new Style({
      image: new Icon({
        // NB: this does not seem to work with imported svgs. They need to be inlined as strings.
        src: `data:image/svg+xml;utf8,${pinSvg}`,
      }),      
    }),
    new Style({
      text: new Text({
        text: text,
        fill: new Fill({ color: "#09334B" }),
        font: `${LABELFONTSIZE} ${FONTFAMILY}`,
        offsetY: 16,
      }),
    })
  ]
  };
  return pointStyle;
};


const getCentralPointStyle = () => {
  const centralPointStyle = (feature) => {    
    const color = feature && feature.id_ ? boundaryColor(feature.id_) : MIKE_COLORS.BRANDBLUE_DEFAULT
    return new Style({
      image: new Circle({
        radius: 7,
        fill: new Fill({color: color})
      })
    });
  };
  return centralPointStyle;
};

const mapContent = (state = initState, action: IAction) => {
  switch (action.type) {  
    case ActionType.POINTS_LOADING_Z: {
      return {...state, loadingPointZValues: action.data}
    } 
    case ActionType.SET_EXISTING_POINT_NAMES: {
      return { ...state, existingPointNames: action.data.names, uploadedPoints: action.data.uploadedPoints}
    } 
    case ActionType.SET_PREVIOUS_EXTRACTIONS: {
      return { ...state, previousExtractions: action.data}
    }
    case ActionType.LOADING_PREVIOUS_EXTRACTIONS: {
      return { ...state, loadingPreviousExtractions: action.data}
    }
    case ActionType.MAPCONTENT_SET_ELEVATION_NAME: {
      return {...state, elevationName: action.data}
    }
    case ActionType.RESET_AUTO_MESH: {
      const interpolating = action.data  
      const { renderedElements, rendered2DElements } = getState();  
      const renderedTwoDLayerIds = rendered2DElements.map((re: IRenderElement) => re.id)
      const twoDLayerIds = [MESH_BOUNDARY_LAYER_ID, MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID]
      twoDLayerIds.forEach((id: string) => {
        if (renderedTwoDLayerIds.includes(id)){
           delete2DData(id)
        }       
      });
      const threeDLayerIds = interpolating ? [MESH_LAYER_ID] : [AUTO_MESH_ID, MESH_LAYER_ID]
      const renderedThreeDLayerIds = renderedElements.map((re: IThreeDRenderElement) => re.id)
      threeDLayerIds.forEach((id: string) => {
        if (renderedThreeDLayerIds.includes(id)){
           deleteData(id)
        }       
      });
      return {...state, selectedMesh: null, mesh: ""}
    }
    case ActionType.CLEAR_MAPCONTENT: {
      const {    
        clearDrawnVectorLayerData, 
        getState
       } = MikeVisualizerLib
      const { rendered2DElements, renderedElements } = getState();

      const twoDLayerIds = rendered2DElements.map((r: IRenderElement) => r.id);
      twoDLayerIds.forEach((id: string) => {delete2DData(id)});
      const threeDLayerIds = renderedElements.map((r: IThreeDRenderElement) => r.id);
      threeDLayerIds.forEach((id: string) => {deleteData(id)});
      clearDrawnVectorLayerData();    

      return {...state, elevationName: "", points: new Array<Feature>(), proj4String: null, epsgCode: null, meshExtent: null, selectedMesh: null, gwm: "", mesh: "", initialPointsGeojson: null, initialPointsGeojsonHandled: false, pointDecimals: 6} // , meshIsWithinGWMErrors: new Array<string>(), meshIsWithinGWMChecked: false
    }
    case ActionType.SET_POINT_DECIMALS: { 
      return {...state, pointDecimals: action.data}
    }
    case ActionType.SET_PROJ4STRING: {  
      const getBoundingBox = (bounds: Array<number>) => {
        return bounds && bounds.length > 3 // bounds needs to include at least 4 values (xMin, xMax, yMin, yMax)
          ? [...bounds.slice(0, 4), 0, 0] // Z bounds are set to 0, because they seem to conflict with open layers' zoom. Zooming is done instead by centering workspace [xmin, ymin, xmax, ymax] coordinates, so zmin & zmax are not relevant & might clash with open layers zoom logic. Worth investgating if this can be prevented as part of MikeVisualizer, although it might make sense to set z bounds for 'pure' 3D visualizations :/
          : null;
      };
      const bounds = getBoundingBox(action.data.initialBounds)
      const mapIsReady = action.data.resetMap ? false : state.mapIsReady
      return {...state, proj4String: action.data.proj4String, epsgCode: action.data.epsgCode, meshExtent: action.data.epsgCode === 4326 ? initialBounds : bounds, mapIsReady: mapIsReady}
    }
    case ActionType.DRAWING_SET: {
      const drawing = action.data;
      switch (drawing) {
        case DRAWING.POINT: {
          enable2DPointDrawing(true)
          break;
        } 
        case DRAWING.OWN_SHORELINE: {
          enable2DPolylineDrawingV2({singleItem:true, olDrawOptions: { snapTolerance: 1 }});
          break;
        }
        case DRAWING.NONE:{
          disableAllDrawingTools();         
          break;
        }
        case DRAWING.AREAOFINTEREST:    
        case DRAWING.MESHOUTLINE: {
          enable2DPolygonDrawing(true)
          break;
        } 
        case DRAWING.ISLANDS: {
          enable2DPolygonDrawing()
          break;
        }        
        default: {
          disableAllDrawingTools();
          break;
        }
      }
      return { ...state, drawing: action.data };      
    }    
    case ActionType.CHART_AXIS_RANGE_SET:
      return {...state, chartAxisRange: action.data}
    case ActionType.SET_COORDINATE_SYSTEMS:
      return {...state, coordinateSystems: action.data}
    case ActionType.UPLOADING_POINTS:
      return {...state, uploadingPoints: action.data}
    case ActionType.UPLOAD_POINTS_URL_SET: 
      return {...state, uploadPointsUrl: action.data, showProjectionSystemDialog: action.data ? true : false, projectionSystemDialogForReaderType: READER.XY}
    case ActionType.FILTER_PROJECTION_SYSTEMS: {
      const filter: string = action.data as string;
      const allCS = state.coordinateSystems;
      const names = allCS.filter((coordinateSystem: IProjection) =>
        coordinateSystem.name.toLowerCase().includes(filter.toLowerCase()),
      );
      return {
        ...state,
        searchProjectionSystemsById: false,
        selectedProjectionSystems: names,
      };
    }
    case ActionType.GET_PROJECTION_SYSTEMS_BY_ID: {
      const id: number = action.data as number;
      const _allCS = state.coordinateSystems;
      const ids = _allCS.filter((projectionSystem: IProjection) =>
        projectionSystem.id.toString().startsWith(id.toString()),
      );
      return {
        ...state,
        searchProjectionSystemsById: true,
        selectedProjectionSystems: ids,
      };
    }
    case ActionType.LOADING_COORDINATE_SYSTEMS:
      return {...state, loadingCoordinateSystems: action.data}
    case ActionType.SETUP_UPLOADED:
      return { ...state, setups: [...state.setups, {name: action.data.name, displayName: action.data.name, id: action.data.id, description: "", isCustom: true}]}
    case ActionType.LOADING_SETUPS:
      return { ...state, loadingSetups: action.data}
    case ActionType.SETUPS_SET:
      return { ...state, setups: action.data}
    case ActionType.DATALINK_EXTRACTION_BUILDER_DATA_LOADING:
      return { ...state, loadingDataLinkExtractionBuilderData: action.data}
    case ActionType.DATALINK_SET_SELECTED_PROVIDER: {
      const {provider, type} = action.data      
      const newProviders = state.dataLinkProviders.map((p: IDataLinkProvider) => {
        return p.id === type ? {
          ...p,
          selected: provider
        } : p}) 
        let selectedProviders = new Array<IDataLinkProvider | IDisplayProvider>();
        newProviders.forEach((p: IDataLinkProvider) => {
          if (p.selected && p.selected.name !== DL_WATERLEVEL_PROVIDER){
            selectedProviders = [...selectedProviders, p.selected]
          }
        })  
        const ids = selectedProviders.map((p: IDataLinkProvider | IDisplayProvider) => p.name)     
        const providers = state.dataLinkExtractionBuilderData?.providers;
        const providersToCheck = providers.filter((p: IProvider) => ids.includes(p.name))
        const {startDate, endDate} = getDataLinkOverlappingPeriod(providersToCheck);
        const extractionStart = state.extractionPeriodStart ? state.extractionPeriodStart : startDate
        const extractionEnd = state.extractionPeriodEnd ? state.extractionPeriodEnd : endDate
        const sd = new Date(extractionStart) < new Date(startDate) ? startDate : extractionStart
        const ed = new Date(extractionEnd) > new Date(endDate) ? endDate : extractionEnd
        return { 
          ...state, 
          dataLinkProviders: newProviders,
          dataLinkOverlappingPeriod: startDate && endDate ? [startDate, endDate] : new Array<string>(),        
          extractionPeriodStart: sd,
          extractionPeriodEnd: ed,
        }
      
    }
    case ActionType.DATALINK_EXTRACTION_BUILDER_DATA_SET:
    {
      const extractionBuilderData: IExtractionBuilderData = action.data
      const providers = extractionBuilderData.providers 
    
      if (providers){
        const fc = extractionBuilderData.forcings.forcingsCollapsed.forcingsCollapsedItems
        const forcingsCollapsedWindItems = fc.filter((f: ICollapsedItem) => f.items.includes(DL_WIND_ITEM))
        let windProviders = [];
        forcingsCollapsedWindItems.forEach((wind: ICollapsedItem) => {
          windProviders = windProviders.concat(wind.providers.filter((p: IDisplayProvider) => MOOD.includes(p.mdsTenant)));
        })
        const forcingsCollapsedWaterLevelItems = fc.filter((f: ICollapsedItem) => f.items.includes(DL_WATERLEVEL_ITEM))
        let waterlevelProviders = [];
        forcingsCollapsedWaterLevelItems.forEach((wl: ICollapsedItem) => {
          waterlevelProviders = waterlevelProviders.concat(wl.providers.filter((p: IDisplayProvider) => p.name === DL_WATERLEVEL_PROVIDER || MOOD.includes(p.mdsTenant)));
        })        
        const boundariesCollapsed = extractionBuilderData.boundaries.boundariesCollapsed        
        const wavesProviders = boundariesCollapsed.providersCollapsed.filter((p: IDisplayProvider) => MOOD.includes(p.mdsTenant));        
        const defaultWaterLevelProvider = waterlevelProviders.find((p: IDisplayProvider) => p.name === DL_WATERLEVEL_PROVIDER)
        const selectedWaterLevelProvider = defaultWaterLevelProvider !== undefined ? defaultWaterLevelProvider : waterlevelProviders.length > 0 ? waterlevelProviders[0] : null
        const defaultWindProvider = windProviders.find((p: IDisplayProvider) => p.name === DL_WIND_PROVIDER)
        const selectedWindProvider = defaultWindProvider !== undefined ? defaultWindProvider : windProviders.length > 0 ? windProviders[0] : null
        const defaultWavesProvider = wavesProviders.find((p: IDisplayProvider) => p.name === DL_WAVE_PROVIDER)
        const selectedWavesProvider = defaultWavesProvider !== undefined ? defaultWavesProvider : wavesProviders.length > 0 ? wavesProviders[0] : null       
        const ids = [selectedWaterLevelProvider, selectedWindProvider, selectedWavesProvider].filter((p: IDisplayProvider) => p && p.name && p.name !== DL_WATERLEVEL_PROVIDER).map((p: IDisplayProvider) => p.name)
        const {startDate, endDate} = getDataLinkOverlappingPeriod(providers.filter((p: IProvider) => ids.includes(p.name)))
        const extractionStart = state.extractionPeriodStart ? state.extractionPeriodStart : startDate
        const extractionEnd = state.extractionPeriodEnd ? state.extractionPeriodEnd : endDate
        return { ...state, 
          dataLinkExtractionBuilderData: action.data, 
          dataLinkOverlappingPeriod: startDate && endDate ? [startDate, endDate] : new Array<string>(),
          extractionPeriodStart: extractionStart,
          extractionPeriodEnd: extractionEnd,
          dataLinkProviders: [
            {
              id: WATERLEVELS,
              name: intl.formatMessage({id: 'boundaryConditions.providers.waterLevels'}),
              providers: waterlevelProviders,
              selected: selectedWaterLevelProvider
            },
            {
              id: WIND,
              name: intl.formatMessage({id: 'boundaryConditions.providers.wind'}),
              providers: windProviders,
              selected: selectedWindProvider
            },
            {
              id: WAVES,
              name: intl.formatMessage({id: 'boundaryConditions.providers.waves'}),
              providers: wavesProviders,
              selected: selectedWavesProvider
            }
          ]
        }
      }
      return { ...state, 
        dataLinkExtractionBuilderData: action.data, 
        dataLinkOverlappingPeriod: new Array<string>(),
        extractionPeriodStart: "",
        extractionPeriodEnd: "",
        dataLinkProviders: new Array<IDataLinkProvider>()
      }
    }
      
    case ActionType.LOADING_DATALINK_MESH:
      return { ...state, loadingDataLinkMesh: action.data}
    case ActionType.CHART_SELECTED_BULLET_SET:{
      const {id, forTestRun} = action.data
      const updatedData = forTestRun ? state.chartBullets.map(d => {return {...d, isSelectedForTestRun: d.id === id}}) :
       state.chartBullets.map(d => d.id === id ? {...d, isSelected: d.isSelected ? false : true} : d)
       const selectedEvents = updatedData.filter((event: IChartBullet) => event.isSelected).map((event: IChartBullet) => event.Time)  
      return { ...state, chartBullets: updatedData, selectedEvents: selectedEvents}
    }
     
    case ActionType.BOUNDARY_LINES_SET: {           
      update2DData({type: "FeatureCollection", features: action.data}, MESH_BOUNDARY_LAYER_ID, null, null, 1, getBoundaryStyle() as any)     
      return state
    } 
    case ActionType.CHART_DATA_GET:  
      return { ...state, selectedChartXItem: action.data.xItem, selectedChartYItem: action.data.yItem }   
    case ActionType.SELECTED_BOUNDARY_OR_FORCING_SET:
      return { ...state, selectedBoundaryOrForcing: action.data}
    case ActionType.LOADING_CHART_DATA:
      return { ...state, loadingChartData: action.data}
    case ActionType.CHART_DATA_SET: {
      const {data, axises} = action.data
      const config: IFastWaveConfig = state.fastWaveConfig 
      const testRunDate = config && config.test_event_time ? config.test_event_time : ""
      const selEvents = state.selectedEvents
      const initialData = state.initialChartData
      const tId = initialData.axises[0].id
      const xId = axises[0].id // state.selectedChartXItem
      const yId = axises[1].id
      const xIsTime = xId === tId
     
      const bullets = data.map((d: any, index: number) => {return {Time: d[tId],x: xIsTime ? parseToTime(d[tId]) : d[xId], y:  d[yId], id: index.toString(), isSelectedForTestRun: d[tId] === testRunDate, isSelected: selEvents.includes(d[tId])}})
      return { ...state, chartData: action.data, chartBullets: bullets, loadingChartData: false }
    }
      
    case ActionType.INITIAL_CHART_DATA_SET:{
      const {items, axises, data } = action.data 
      if (items && items.length > 1 && axises && axises.length === 2){
        const config: IFastWaveConfig = state.fastWaveConfig 
        const testRunDate = config && config.test_event_time ? config.test_event_time : ""
        const timeItemId = axises[0].id
        const previewItemId = axises[1].id
        const x = items && timeItemId ? items.find(d => d.id === timeItemId): null
        const y = items && previewItemId ? items.find(d => d.id === previewItemId): null
  
        const selEvents = state.selectedEvents
        const bullets = data && data.length > 0 ? data.map((d: any, index: number) => {return {id: index, x: parseToTime(d[timeItemId]), y: d[previewItemId], isSelected: selEvents.includes(d[timeItemId]), isSelectedForTestRun: d[timeItemId] === testRunDate, Time: d[timeItemId]}}) : new Array<IChartBullet>()
  
        return { ...state, chartBullets: bullets, initialChartData: action.data, loadingChartData: false, selectedChartXItem: x, selectedChartYItem: y }
      }
      else{      
        return { ...state, chartBullets: new Array<IChartBullet>(), initialChartData: {
          axises: new Array<IAxis>(), 
          items: new Array<IChartItem>(), 
          data: []
        }, loadingChartData: false, selectedChartXItem: null, selectedChartYItem: null }
      } 
    }
      
    case ActionType.LOADING_CENTRAL_POINTS:
      return { ...state, loadingCentralPoints: action.data}
    case ActionType.CENTRAL_POINTS_SET:  {         
      update2DData({type: "FeatureCollection", features: action.data.centralPoints}, MESH_BOUNDARY_CENTRAL_POINTS_LAYER_ID, null, null, 1, getCentralPointStyle() as any)
      return { ...state, 
        boundaryAndForcings: action.data.boundaryAndForcings, 
        events: action.data.events,
        loadingCentralPoints: false,
        selectedBoundaryOrForcing: action.data.boundaryAndForcings.length > 0 ? action.data.boundaryAndForcings[0] : null,
        selectedEvents: action.data.events,
        totalExplainedVarianceRatio: action.data.totalExplainedVarianceRatio        
      };
    }
    case ActionType.DELETE_OUTPUT_FOLDER: {
      return { ...state, 
        boundaryAndForcings: new Array<IListItem>(),
        events: new Array<string>(), 
        loadingCentralPoints: false,
        selectedBoundaryOrForcing: null,
        selectedEvents: new Array<string>(), 
        totalExplainedVarianceRatio: 0,
        testRunOutput: null         
      };
    }  
    case ActionType.CLEAR_RESULTS: {
      return { ...state, 
        boundaryAndForcings: new Array<IListItem>(),
        events: new Array<string>(), 
        loadingCentralPoints: false,
        selectedBoundaryOrForcing: null,
        selectedEvents: new Array<string>(), 
        totalExplainedVarianceRatio: 0,
        testRunOutput: null       
      };
    }     
    case ActionType.EXTRACTION_PERIOD_START_SET:{
      if (state.dataLinkOverlappingPeriod.length > 1){
        const minDate = new Date(state.dataLinkOverlappingPeriod[0])
        const newMinDate = new Date(action.data)
        if (newMinDate >= minDate){
          return { ...state, extractionPeriodStart: action.data };
        }
      }
      return state;
    }  
    case ActionType.EXTRACTION_PERIOD_END_SET:{
      if (state.dataLinkOverlappingPeriod.length > 1){
        const maxDate = new Date(state.dataLinkOverlappingPeriod[1])
        const newMaxDate = new Date(action.data)
        if (newMaxDate <= maxDate){
          return { ...state, extractionPeriodEnd: action.data };
        }
      }
      return state;
    }      
    case ActionType.SHOW_TESTRUN_DIALOG:
      return { ...state, showTestRunDialog: action.data };
    case ActionType.SHOW_PROJECTION_SYSTEM_DIALOG:
      return {...state, showProjectionSystemDialog: action.data.show, projectionSystemDialogForReaderType: action.data.type}
    case ActionType.SHOW_SUCCESS_DIALOG:
      return { ...state, showSuccessDialog: action.data };
    case ActionType.LOADING_TESTRUN_OUTPUT:
      return { ...state, loadingTestRunOutput: action.data };
    case ActionType.TESTRUN_OUTPUT_SET:{     
      return { ...state, testRunOutput: csvToTable(action.data), loadingTestRunOutput: false };
    }       
    case ActionType.SET_MAP_READY:
      return { ...state, mapIsReady: true }; 
    case ActionType.CONFIG_LAYER_SET:
      return { ...state, fastWaveConfig: action.data.fastWaveConfig }; 
    case ActionType.SET_MESH: { 
      return { ...state, mesh: action.data }; 
    }
    case ActionType.SET_GWM: { 
      return { ...state, gwm: action.data }; 
    }
    case ActionType.MESH_SET_EXTENT: {
      return { ...state, meshExtent: action.data }; 
    }        
    case ActionType.LOAD_POINTS:
      return { ...state, pointDatasetSelectedFromPlatform: action.data };  
    case ActionType.LOADING_CONFIG:
      return { ...state, loadingConfig: action.data };
    case ActionType.LOADING_MESH:
      return { ...state, loadingMesh: action.data };
    case ActionType.MESH_SET:
      return { ...state, meshDataset: action.data };
    case ActionType.BOUNDARYCONDITION_SET:
      return { ...state, boundaryConditionDataset: action.data.boundaryConditionsDataset };
    case ActionType.EXTRACTION_INFO_SET:
      return { ...state, extractionInfoDataset: action.data };
    case ActionType.SET_SELECTED_MESH:
      return { ...state, selectedMesh: action.data.selectedDataset };
/*     case ActionType.SET_MESH_IS_WITHIN_GWM_ERRORS:
      return {...state, meshIsWithinGWMErrors: action.data.errors, meshIsWithinGWMChecked: action.data.checked} */
    case ActionType.LOADING_POINTS:
      return { ...state, loadingPointLayer: action.data };
    case ActionType.POINT_LAYER_SET:
      return { ...state, initialPointsGeojson: action.data};
    case ActionType.POINT_LAYER_INITIAL_DRAW: {      
      const initialPointsHandled = state.initialPointsGeojsonHandled
      const initialPointsGeojson = state.initialPointsGeojson
      if (!initialPointsHandled && initialPointsGeojson){
        const decimals = state.pointDecimals
        const features = initialPointsGeojson.features
        const featuresWithRoundedCoords = toFeaturesWithRoundedCoords(features, decimals)
        update2DData({...initialPointsGeojson, features: featuresWithRoundedCoords}, POINT_LAYER_ID, null, null, 1, getPointStyle() as any);
        return { ...state, points: featuresWithRoundedCoords, initialPointsGeojsonHandled: true};
      }
      else return state
    }      
    case ActionType.POINT_DELETE: {
      const id = action.data
      const remainingPoints = state.points.filter((point: Feature) => point.id !== id)      
      update2DData({
        type: "FeatureCollection",
        features: remainingPoints}, POINT_LAYER_ID, null, null, 1,  getPointStyle() as any);
      return { ...state, points: remainingPoints };
    }
   
    case ActionType.POINT_UPDATE: {
      const {features, save} = action.data 
      const decimals = state.pointDecimals   
      const featuresWithRoundedCoords = toFeaturesWithRoundedCoords(features, decimals)
      if (save){
        const fc: FeatureCollection = {type: "FeatureCollection", features: featuresWithRoundedCoords}  as FeatureCollection  
        update2DData(fc, POINT_LAYER_ID, null, null, 1,  getPointStyle() as any);
      }
      return { ...state, points: featuresWithRoundedCoords };
    }
    default:
      return state;
  }
};

export default mapContent