import  ActionType  from "./ActionType";
import { ICreateMeshImport } from "../model/ICreateMeshImport";
import { ICreateMeshDataset, ICreateMeshInfo, ICreateMeshParameter } from "../reducers/createMesh";
import { IInterpolateMesh } from "../apis/backendApi";
import { MESHTYPE } from "../shared/constants";
import { IMeshInputEntity } from "../model/CreateMeshScenarioOptions";
import { ICreateAutoShorelineParameter } from "../model/IAutoShorelineParameter";

export const loadingScenarios = (loading = true) => ({
  type: ActionType.LOADING_SCENARIOS,
  data: loading
})

export const checkMeshIsExported = (projectId: string) => ({
  type: ActionType.GET_MESH_IS_EXPORTED,
  data: projectId
})

export const setMeshIsExported = (isExported = true) => ({
  type: ActionType.SET_MESH_IS_EXPORTED,
  data: isExported
})

export const checkingMeshIsExported = (checking = true) => ({
  type: ActionType.CHECKING_MESH_IS_EXPORTED,
  data: checking
})


export const setCanInterpolateMesh = (canInterpolateMesh = true) => ({
  type: ActionType.SET_CAN_INTERPOLATE_MESH,
  data: canInterpolateMesh
})

export const abortDotMeshIsExportedCheck = (abort = true) => ({
  type: ActionType.ABORT_DOT_MESH_EXPORTED_CHECK,
  data: abort
})

export const loadingShoreline = (loading = true) => ({
  type: ActionType.LOADING_SHORELINE,
  data: loading
})

export const loadingOutline = (loading = true) => ({
  type: ActionType.LOADING_OUTLINE,
  data: loading
})

export const addShoreline = (shoreline: ICreateMeshDataset) => ({
  type: ActionType.ADD_SHORELINE,
  data: shoreline
})

export const loadingAOI = (loading = true) => ({
  type: ActionType.LOADING_AOI,
  data: loading
})

export const setLoadingIslands = (loading = true) => ({
  type: ActionType.LOADING_ISLANDS,
  data: loading
})

export const setLoadingGebco = (loading = true) => ({
  type: ActionType.LOADING_GEBCO,
  data: loading
})

export const setLoadingBathymetry = (loading = true) => ({
  type: ActionType.LOADING_BATHYMETRY,
  data: loading
})

export const getGebcoBathymetry = (projectId: string) => ({
  type: ActionType.GET_GEBCO,
  data: projectId
})

export const getShoreline = () => ({
  type: ActionType.GET_SHORELINE
})

export const initAutoMesh = (scenarios: Array<string>, scenario: string, meshType = MESHTYPE.AUTOMESH) => ({
  type: ActionType.CLEAR_AUTO_MESH,
  data: {scenarios, scenario, meshType}
})

export const setVtkItemId = (vtkItemId: string, layerId: string) => ({
  type: ActionType.VTKITEM_SET,
  data: {vtkItemId, layerId}
})

export const setVtk = (vtk: string, layerId: string, title = "", projectId: string) => ({
  type: ActionType.VTK_SET,
  data: {vtk, layerId, title, projectId}
})

export const deleteVtkItems = (itemsToDelete: Array<ICreateMeshDataset>, datasetType: string) => ({
  type: ActionType.DELETE_VTKITEMS,
  data: {itemsToDelete, datasetType}
})

export const deleteProjection = () => ({
  type: ActionType.DELETE_PROJECTION
})

export const resetAutoMesh = (interpolating = false) => ({
  type: ActionType.RESET_AUTO_MESH,
  data: interpolating
})

export const uploadDrawnGeometry = (featureCollection, datasetType: string, epsg: number) => ({
  type: ActionType.DRAWN_AREA_UPLOAD,
  data: {featureCollection, datasetType, epsg}
})

export const updateParameters = (parameters: any, entityType: string, entityId?: string) => ({
  type: ActionType.UPDATE_PARAMETERS,
  data: { parameters, entityType, entityId }
})

export const updateSelectedAOIs = (vtkItemIds: Array<string>) => ({
  type: ActionType.UPDATE_SELECTED_AOIS,
  data: vtkItemIds
})

export const setSelectedShoreline = (vtkItemId: string) => ({
  type: ActionType.SET_SELECTED_SHORELINE,
  data: vtkItemId
})

export const pendingImportSet = (createMeshImport: ICreateMeshImport) => ({
  type: ActionType.PENDING_IMPORT_SET,
  data: createMeshImport
})

export const createMeshWorkspace = (srid?: number) => ({
  type: ActionType.CREATE_WORKSPACE,
  data: srid
})

export const setAutoShorelineParameters = (parameter: Array<ICreateMeshParameter>) => ({
  type: ActionType.SET_AUTO_SHORELINE_PARAMETERS,
  data: parameter
})
export const setCreateMeshPayload = (payload: ICreateMeshInfo, meshEntities: Array<IMeshInputEntity>) => ({
  type: ActionType.SET_CREATE_MESH_PAYLOAD,
  data: {payload, meshEntities}
})

export const getCreateMeshPayload = () => ({
  type: ActionType.GET_CREATE_MESH_SCENARIO_PARAMS
})

export const setMeshType = (type: string, updateConfig = false) => ({
  type: ActionType.MESHTYPE_SET,
  data: {changeToType: type, updateConfig} 
})

export const setWorkspaceScenarios = (scenarios: Array<string>) => ({
  type: ActionType.SET_SCENARIOS,
  data: scenarios
})

export const setWorkspaceScenario = (scenario: string) => ({
  type: ActionType.SET_SCENARIO,
  data: scenario
})

export const createAutomatedShoreline = (parameters: ICreateAutoShorelineParameter) => ({
  type: ActionType.CREATE_AUTO_SHORELINE,
  data: parameters
})

export const getWorkspaceScenarios = () => ({
  type: ActionType.GET_SCENARIOS
})

export const createAutoMesh = ()  => ({
  type: ActionType.CREATE_MESH
})

export const interpolateMesh = (bathymetries: Array<IInterpolateMesh>)  => ({
  type: ActionType.INTERPOLATE_MESH,
  data: bathymetries
})

export const setInterpolatingMesh = (interpolating = true) => ({
  type: ActionType.INTERPOLATING_MESH,
  data: interpolating
})

export const creatingMesh = (creating = true) => ({
  type: ActionType.CREATING_MESH,
  data: creating
})

export const creatingWorkspace = (creating = true) => ({
  type: ActionType.CREATING_WORKSPACE,
  data: creating
})