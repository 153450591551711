/** @jsxImportSource @emotion/react */
/* eslint-disable react-refresh/only-export-components */
import { css } from '@emotion/react';
import React, { useCallback, useMemo } from 'react'
import Typography from '@mui/material/Typography'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import BoundaryConditions from '../BoundaryConditions';
import QualityControl from '../QualityControl';
import Setup from '../Setup';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useIntl } from 'react-intl';
import MikeButton from '../mike-button';
import { IGetProject } from '../../model/IGetProject';
import { IState } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { IGetDataset } from '../../model/IGetDataset';
import { IFastWaveConfig, IJob } from '../../model/IFastWaveConfig';
import { cancelJobs, createContainer } from '../../actions/job';
import { CANCELABLE_JOBS, CSVEXTENSION, DATALINKJOBS, DATASETS, DFS0EXTESION, JOBS, MESHTYPE } from '../../shared/constants';
import { getJob } from '../../helpers/fastwave';
import { DATA_LINK_JOBS_IN_PROGRESS, JOBS_IN_PROGRESS } from '../../model/IJobStatus';
import AutoMesh from '../AutoMesh';
import InitialSelection, { IWarning } from '../InitialSelection';
import { createMeshWorkspace } from '../../actions/createMesh';
import Points from '../Points';
import { publishDataToMOOD } from '../../actions/mood';
import { downloadEvents, downloadResults } from '../../actions/projectContent';

import { EVENT_DOWNLOAD, EVENT_DOWNLOAD_DESC, EVENT_PUBLISHED, EVENT_PUBLISHED_DESC } from '../../shared/matomoEvents'
import MikeDialog from '../DialogComponents/MikeDialog'
import { IListItem } from '../../reducers/mapContent'
import { Feature, Point } from 'geojson'
import { exportAndDownloadDataset } from '../../actions/exportAndDownload'
import { formatDate } from '../../mike-shared-helpers/date';
import mikeSharedTheme from '../../styles/mikeSharedTheme';
import MikeStickyPanel from '../mike-sticky-panel';
import { MikeStickyPanelHeaderContainer } from '../mike-sticky-panel/MikeStickyPanelHeaderContainer';
import MikeStickyPanelContent from '../mike-sticky-panel/MikeStickyPanelContent';
import { MikeStickyPanelBottomActions } from '../mike-sticky-panel/MikeStickyPanelBottomActions';


const buttonsContainerStyle = css`
  display: flex;
  justify-content: space-between;
  width: 100vw;  
  padding-right: ${mikeSharedTheme.spacing(1)}; 
  height: 60px;
`

export const downloadButtonStyle = {
  padding: 0, 
  height: '1.286rem',
  whiteSpace: 'nowrap',
  minWidth: 0,
  minHeight: 0,  
  '&:disabled': {
    height: '1.286rem',
    minWidth: 0
  }
};

export const downloadTextStyle = css`
  white-space: nowrap;  
  padding-right: ${mikeSharedTheme.spacing(0.5)};
`;

const moodTextStyle = css`
  white-space: nowrap;
  padding-right: ${mikeSharedTheme.spacing(0.5)};
`;

export const orTextStyle = css`
  padding-left: ${mikeSharedTheme.spacing(0.5)};
  padding-right: ${mikeSharedTheme.spacing(0.5)};
`;

const rootStyle = css`
  width: ${mikeSharedTheme.spacing(100)};
  `;

const moodButtonStyle = {   
    padding: 0,   
    paddingTop: 0.5,   
    height: '1.286rem',
    whiteSpace: 'nowrap',
    '&:disabled': {
      height: '1.286rem'
    }
  }


const flexStyle = css`
  display: flex;
  align-items: center;
`
const containerStyle = css` 
  height: calc(100vh - 200px);
`

const stepperStyle = css`  
  &.MuiPaper-root {
    background-color: ${mikeSharedTheme.palette.lightGrey.main};
  } 
`;

const Sidebar = () => { 
  const intl = useIntl()  
  const dispatch = useDispatch();
  const navigate = useNavigate(); 
  const { id } = useParams();

  const dataLinkExtractionBuilderData = useSelector(
    (state: IState) => state.mapContent.dataLinkExtractionBuilderData
  ); 

  const config: IFastWaveConfig = useSelector(
    (state: IState) => state.mapContent.fastWaveConfig
  );  

  const points = useSelector(
    (state: IState) => state.mapContent.points
  );

  const project: IGetProject | null = useSelector(
    (state: IState) => state.projectContent.project
  );

  const hasModelResults: boolean = useSelector((state: IState) => state.projectContent.hasModelResults)
  const modelResultsUpdatedOn: string = useSelector((state: IState) => state.projectContent.modelResultsUpdatedOn)

  const selectedMesh: IGetDataset  = useSelector(
    (state: IState) => state.mapContent.selectedMesh
  ); 

  const boundaryAndForcings: Array<IListItem> = useSelector(
    (state: IState) => state.mapContent.boundaryAndForcings
  ); 

  const selectedEvents = useSelector((state: IState) => state.mapContent.selectedEvents);

  const jobs: Array<IJob> = useSelector(
    (state: IState) => state.job.jobs
  ); 

  const { meshType }  = useSelector((state: IState) => state.createMesh);

  const publishingResultsToMOOD: boolean = useSelector(
    (state: IState) => state.mood.publishingResultsToMOOD
  ); 
  
  const canCreateContent = project && project.capabilities && project.capabilities.canCreateContent
  const canReadContent = project && project.capabilities && project.capabilities.canReadContent
  const [warning, setWarning] = React.useState<IWarning | null>(null);  

  const resultLabel = useMemo(() => {
    let label = intl.formatMessage({id: 'sideBar.downloadResults'});
    if (hasModelResults){
      label = label + " " + intl.formatMessage({id: 'sideBar.completedOn'}) + " " + formatDate(modelResultsUpdatedOn, true);
    }
    return label
  }, [hasModelResults, intl, modelResultsUpdatedOn])

  const {runModelJobIsRunning, runMDAJobIsRunning, dataExtractionJobIsRunning} = useMemo(() => {
    const runModelJob = getJob(jobs, JOBS.AUTOSETUP)
    const runModelJobStatus = runModelJob !== undefined && runModelJob.status ? runModelJob.status : ""
    const runMDAJob = getJob(jobs, JOBS.MDA)
    const runMDAJobStatus = runMDAJob !== undefined && runMDAJob.status ? runMDAJob.status : ""
    const job = getJob(jobs, DATALINKJOBS.DATAEXTRACTION)
    const jobStatus = job !== undefined && job.status ? job.status : ""  
    
    return {
      runModelJobIsRunning: runModelJobStatus && JOBS_IN_PROGRESS.includes(runModelJobStatus.toLowerCase()) , 
      runMDAJobIsRunning: runMDAJobStatus && JOBS_IN_PROGRESS.includes(runMDAJobStatus.toLowerCase()),
      dataExtractionJobIsRunning: DATA_LINK_JOBS_IN_PROGRESS.includes(jobStatus.toLowerCase())
    }
  }, [jobs])  


  const hasInitialSelection = useMemo(() => {
    if (config){
      if (meshType === MESHTYPE.UPLOAD){
        return selectedMesh !== null;
      }
      else { 
        return config.createMeshConfig 
      }      
    }  
    return false
  }, [config, meshType, selectedMesh])   
  
  const readyForBoundaryConditions = useMemo(() => {
    return canCreateContent && selectedMesh && points.length > 0 && dataLinkExtractionBuilderData ? true : false
  } , [canCreateContent, dataLinkExtractionBuilderData, points.length, selectedMesh])

  const dataExtracted = useMemo(() => {
    return dataExtractionJobIsRunning ? false : canCreateContent && config && config.data_link_output_file && config.data_link_output_file.dataset_id ? true : false
  } , [canCreateContent, config, dataExtractionJobIsRunning])

  const readyForQualityControl = useMemo(() => {
    return dataExtracted &&  config.start_time && config.end_time ? true : false
  } , [config.end_time, config.start_time, dataExtracted ])

  const readyToMoveToSetupAndModelPage = useMemo(() => {
    return runMDAJobIsRunning  ? false : readyForQualityControl && config.test_event_time && boundaryAndForcings.length > 0  ? true : false 
  } , [config.test_event_time, readyForQualityControl, boundaryAndForcings, runMDAJobIsRunning])

  const readyForModelRun = useMemo(() => {
    return runMDAJobIsRunning  ? false : readyForQualityControl && config.setup && boundaryAndForcings.length > 0 ? true : false
  } , [boundaryAndForcings.length, config.setup, readyForQualityControl, runMDAJobIsRunning])  
  
  const canPublishModelResults = useMemo(() => {
    return canReadContent && hasModelResults && selectedMesh && !runModelJobIsRunning 
  }, [hasModelResults, runModelJobIsRunning, selectedMesh, canReadContent])

  function usePath() {
    return useLocation().pathname
  }

  const currentPath = usePath()

  const activeStep = useMemo(() => {
    switch(currentPath){
      case '/project/' + id:
      case  '/project/' + id + '/initialselection':
        return 0;
      case  '/project/' + id + '/createmesh':
        return 1;
      case  '/project/' + id + '/points':
        return meshType === MESHTYPE.AUTOMESH ? 2 : 1;
      case '/project/' + id + '/boundaryconditions':
        return meshType === MESHTYPE.AUTOMESH ? 3 : 2;
      case '/project/' + id + '/control':
        return meshType === MESHTYPE.AUTOMESH ? 4 : 3;
      case '/project/' + id + '/setup':
        return meshType === MESHTYPE.AUTOMESH ? 5 : 4;
      default:
        return -1;
    } 
  }, [currentPath, id, meshType]);   
 
  function getStepContent(step: number, type: string) {    
    switch (step) {
      case 0:
        return <InitialSelection/>;
      case 1:
        return type === MESHTYPE.AUTOMESH ?
         <AutoMesh/> : <Points/>;
       case 2:
        return type === MESHTYPE.AUTOMESH ? 
        <Points/> : <BoundaryConditions/>;
      case 3:
        return type === MESHTYPE.AUTOMESH ? 
         <BoundaryConditions/> : <QualityControl/>;
      case 4:
        return type === MESHTYPE.AUTOMESH ?
         <QualityControl/> : <Setup/>;
      case 5:
        return <Setup/>;
      default:
        return null;
    }   
  }

  function showDownloadPointAndMeshLinks(step: number, type: string) {
    return (type === MESHTYPE.AUTOMESH && step === 2) || (type === MESHTYPE.UPLOAD && step === 1)
  }

  function showDownloadEvents(step: number, type: string) {
    return (type === MESHTYPE.AUTOMESH && step === 4) || (type === MESHTYPE.UPLOAD && step === 3)
  }

  function getNextButtonDisabledStatus(step: number, type: string) {
    switch (step) {
      case 0:
        return !hasInitialSelection;
      case 1:
        return type === MESHTYPE.AUTOMESH ? !selectedMesh : !readyForBoundaryConditions
      case 2:
        return type === MESHTYPE.AUTOMESH ? !readyForBoundaryConditions : !readyForQualityControl;
      case 3:
        return type === MESHTYPE.AUTOMESH ? !readyForQualityControl : !readyToMoveToSetupAndModelPage;
      case 4:
        return type === MESHTYPE.AUTOMESH ? !readyToMoveToSetupAndModelPage : !readyForModelRun;
      case 5:
        return !readyForModelRun;
      default:
        return true;
    }
  }

  function getNextButtonActiveStatus(step: number){
    switch (step) {      
      case 3:
        return runMDAJobIsRunning;
      case 4:
        return runMDAJobIsRunning;
      case 5:
        return runModelJobIsRunning;
      default:
        return false;
    }    
  }

  const steps = useMemo(() => {
    if (meshType === MESHTYPE.AUTOMESH){
      return [intl.formatMessage({id: 'nav.initialselection'}), 
      intl.formatMessage({id: 'nav.createmesh'}), 
      intl.formatMessage({id: 'nav.points'}), 
      intl.formatMessage({id: 'nav.boundaryConditions'}), 
      intl.formatMessage({id: 'nav.qualityControl'}),
      intl.formatMessage({id: 'nav.setupAndRun'})
      ];
    }
    else {
      return [intl.formatMessage({id: 'nav.initialselection'}),     
    intl.formatMessage({id: 'nav.points'}), 
    intl.formatMessage({id: 'nav.boundaryConditions'}), 
    intl.formatMessage({id: 'nav.qualityControl'}),
    intl.formatMessage({id: 'nav.setupAndRun'})
    ];
  }  
}, [intl, meshType]) 
    

  const handleNextButton = useCallback(() => {
    switch (activeStep) {
      case 0:
        if (meshType === MESHTYPE.AUTOMESH){        
          dispatch(createMeshWorkspace(config.createMeshConfig.targetSrid ? config.createMeshConfig.targetSrid : 4326)) 
          navigate('/project/' + project.id + '/createmesh')
        }
        else{
          navigate('/project/' + project.id + '/points');
        }
        
        break; 
      case 1:
        meshType === MESHTYPE.AUTOMESH ? 
        navigate('/project/' + project.id + '/points') :
        navigate('/project/' + project.id + '/boundaryconditions');
        break;
      case 2:
        if (meshType === MESHTYPE.AUTOMESH){
          navigate('/project/' + project.id + '/boundaryconditions');
        }       
        else{
          // dispatch(createContainer(JOBS.MDA, null, false)); 
          navigate('/project/' + project.id + '/control');
        }        
        break;  
      case 3: 
        if (meshType === MESHTYPE.AUTOMESH){
          // dispatch(createContainer(JOBS.MDA, null, false)) 
          navigate('/project/' + project.id + '/control');
        }
        else{
          // dispatch(saveEvents(project.id))
          navigate('/project/' + project.id + '/setup')
        }
        
        break;
      case 4:
        if (meshType === MESHTYPE.AUTOMESH){
        // dispatch(saveEvents(project.id))
        navigate('/project/' + project.id + '/setup')
        }
        else{
          setWarning({warning: intl.formatMessage({id: 'warnings.runModel'}), type: ""})
        }
        break;
      case 5:
        setWarning({warning: intl.formatMessage({id: 'warnings.runModel'}), type: ""})         
        break;
      default:
        break;
    }    
  }, [activeStep, meshType, navigate, project, intl, dispatch, config])

  const handleCancelJobButton = useCallback(() => {
    const ids = jobs.filter(job => CANCELABLE_JOBS.includes(job.rowKey)).map(job => job.jobId)
    dispatch(cancelJobs(ids))
  }, [dispatch, jobs])  

  const getCancelJobButtonDisabledStatus = useCallback(() => {  
    const ids = jobs.filter(job => CANCELABLE_JOBS.includes(job.rowKey) && JOBS_IN_PROGRESS.includes(job.status.toLowerCase())).map(job => job.jobId)
    return ids.length === 0
  }, [jobs]) 

  const handleBackButton = useCallback(() => {
    switch (activeStep) {    
      case 1:
        navigate('/project/' + project.id + '/initialselection');
        break;
      case 2:
        meshType === MESHTYPE.AUTOMESH ?
        navigate('/project/' + project.id + '/createmesh') :
        navigate('/project/' + project.id + '/points');
        break;   
      case 3:
        meshType === MESHTYPE.AUTOMESH ?
        navigate('/project/' + project.id + '/points') :
        navigate('/project/' + project.id + '/boundaryconditions');
        break;
      case 4: 
        meshType === MESHTYPE.AUTOMESH ?     
        navigate('/project/' + project.id + '/boundaryconditions') :
        navigate('/project/' + project.id + '/control');
        break;
      case 5:
        navigate('/project/' + project.id + '/control')
        break;
      default:
        break;
    }    
  }, [activeStep, navigate, project, meshType])  

  const lastStep = useMemo(() => {
    return meshType === MESHTYPE.AUTOMESH ? 5 : 4
  }, [meshType])

  const publishToMood = useMemo(() => {
    const prefix = intl.formatMessage({id: 'sideBar.publishToMOOD'})
    return publishingResultsToMOOD ? prefix  + " " +  intl.formatMessage({id: 'sideBar.mood'}) : prefix
  }, [intl, publishingResultsToMOOD])

  const handleDownloadPoints = useCallback(() => {
    const coords = points.map((p: Feature<Point>) => [p.id, ...p.geometry.coordinates].join())
    const lines = coords.join("\n")
    const file = new File([lines], 'MMS_Points.xy', {
      type: 'text/plain',
    })
    const link = document.createElement('a')
    const url = URL.createObjectURL(file)
    link.href = url
    link.download = file.name
    link.setAttribute('target', '_blank');  
    document.body.appendChild(link) 
    link.click()
    document.body.removeChild(link)
    window.URL.revokeObjectURL(url)
  }, [points])

  const handleDownloadMesh = useCallback(() => {   
    dispatch(exportAndDownloadDataset(selectedMesh.name, selectedMesh.id, 
      {importData: {name: selectedMesh.name, reader: "FileReader",writer: "FileWriter"}}, 
      DATASETS.DOTMESH, true
    ) )
  }, [selectedMesh, dispatch])
  
  const handlePublishDataToMOOD = () => {
    window._paq.push(['trackEvent', EVENT_PUBLISHED, EVENT_PUBLISHED_DESC]);
    dispatch(publishDataToMOOD())
  }

  const handleDownloadResults = (fileExtension: string) => {
    window._paq.push(['trackEvent', EVENT_DOWNLOAD, EVENT_DOWNLOAD_DESC]);
    dispatch(downloadResults(fileExtension))
  }

  const handleDownloadEvents = () => {
    dispatch(downloadEvents())
  }

  const handleStepClicked = useCallback((stepLabel: string) => {
    if (project){
      switch (stepLabel) {    
        case intl.formatMessage({id: 'nav.initialselection'}):
          navigate('/project/' + project.id + '/initialselection');
          break;
        case intl.formatMessage({id: 'nav.createmesh'}):       
          navigate('/project/' + project.id + '/createmesh');     
          break;   
        case intl.formatMessage({id: 'nav.points'}):        
          navigate('/project/' + project.id + '/points');
          break;
        case intl.formatMessage({id: 'nav.boundaryConditions'}):             
          navigate('/project/' + project.id + '/boundaryconditions');
          break;
        case intl.formatMessage({id: 'nav.qualityControl'}):
          navigate('/project/' + project.id + '/control')
          break;
        case intl.formatMessage({id: 'nav.setupAndRun'}):
          navigate('/project/' + project.id + '/setup')
          break;
        default:
          break;
      }
    }    
  }, [intl, navigate, project])

  const handleOnCancel = (_event?, reason?) => {
    if(!reason || (reason !== 'backdropClick' && reason !== 'escapeKeyDown')) {
      setWarning(null);
    }
  }
  
  const handleWarningConfirmed = useCallback(() => {   
    setWarning(null);
    dispatch(createContainer(JOBS.AUTOSETUP))   
  }, [dispatch])

  return (
   
     
    <div css={rootStyle}>
      <MikeDialog 
        open={warning && warning.warning ? true : false} 
        onCancel={handleOnCancel} 
        onOk={handleWarningConfirmed}
        dialogTitle={intl.formatMessage({id: 'warnings.startCloudExecution'})}
        contentTitle={""}
        message={warning && warning.warning ? warning.warning : ''}   
        okButtonLabel={intl.formatMessage({id: 'warnings.runModelButton'})} 
        cancelButtonLabel={intl.formatMessage({id: 'warnings.backButton'})} 
      />
      <MikeStickyPanel>
        <MikeStickyPanelHeaderContainer>
          <Stepper css={stepperStyle} activeStep={activeStep} alternativeLabel>
            {steps.map((label) => {         
              return (
                <Step key={label} onClick={() => handleStepClicked(label)} disabled={project === null}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper> 
        </MikeStickyPanelHeaderContainer>
        <MikeStickyPanelContent>
          <div css={containerStyle}>
            {getStepContent(activeStep, meshType)}
          </div>          
        </MikeStickyPanelContent>
        <MikeStickyPanelBottomActions>
        <div css={buttonsContainerStyle}>
          {activeStep === lastStep ?
            <div>
              <div css={flexStyle}>               
                <Typography css={downloadTextStyle} variant="body2">{resultLabel}</Typography> 
                <MikeButton sx={downloadButtonStyle} variant="text" onClick={() => handleDownloadResults(CSVEXTENSION)} disabled={!canPublishModelResults}>
                  {intl.formatMessage({id: 'sideBar.asCSV'})}                  
                </MikeButton> 
                <Typography css={orTextStyle} variant="body2">{intl.formatMessage({id: 'sideBar.or'})}</Typography> 
                <MikeButton sx={downloadButtonStyle} variant="text" onClick={() => handleDownloadResults(DFS0EXTESION)} disabled={!canPublishModelResults}>
                  {intl.formatMessage({id: 'sideBar.asDFS0'})}                  
                </MikeButton>
              </div>
              <div css={flexStyle}>
                <Typography css={moodTextStyle} variant="body2">{publishToMood}</Typography>
                <MikeButton sx={moodButtonStyle} id="publishButton" variant="text" active={publishingResultsToMOOD} onClick={handlePublishDataToMOOD} disabled={!canPublishModelResults}>
                  {intl.formatMessage({id: 'sideBar.mood'})}               
                </MikeButton>
              </div>
            </div> : showDownloadPointAndMeshLinks(activeStep, meshType) ? 
            <div> 
              <div css={flexStyle}>             
                <MikeButton sx={downloadButtonStyle} id="pointButton" variant="text" onClick={handleDownloadPoints} disabled={points.length === 0}>
                  {intl.formatMessage({id: 'sideBar.downloadPoints'})}               
                </MikeButton>
              </div>
              <div css={flexStyle}>             
                <MikeButton sx={downloadButtonStyle} id="meshButton" variant="text" onClick={handleDownloadMesh} disabled={!selectedMesh}>
                  {intl.formatMessage({id: 'sideBar.downloadMesh'})}               
                </MikeButton>
              </div>
            </div>

            : showDownloadEvents(activeStep, meshType) ? <div>
              <div css={flexStyle}>             
                <MikeButton id="eventsButton" variant="text" onClick={handleDownloadEvents} disabled={selectedEvents.length === 0}>
                  {intl.formatMessage({id: 'sideBar.downloadEvents'})}               
                </MikeButton>
              </div>
            </div> : <div></div>
          }
         <div css={flexStyle}>
          {activeStep === lastStep &&
            <MikeButton size="small" id="cancelJobButton" variant="text" onClick={handleCancelJobButton} disabled={getCancelJobButtonDisabledStatus()}>
              {intl.formatMessage({id: 'sideBar.cancelJob'})}               
            </MikeButton>
          }
          {activeStep > 0 &&
            <MikeButton size="small" id="backButton" variant="text" onClick={handleBackButton}>
              {intl.formatMessage({id: 'sideBar.back'})}               
            </MikeButton>
          }
          {activeStep <= lastStep &&        
            <MikeButton size={activeStep !== lastStep ? "small" : "medium"} variant="contained" active={getNextButtonActiveStatus(activeStep)} disabled={getNextButtonDisabledStatus(activeStep, meshType)} id="nextButton" color="secondary" onClick={handleNextButton}>             
              {intl.formatMessage({id: activeStep === lastStep ? 'sideBar.runModel' : 'sideBar.nextStep'})}            
            </MikeButton>
          }
          </div>
          </div>
        </MikeStickyPanelBottomActions>     
      </MikeStickyPanel>
    </div>
    
  )
}

export default Sidebar