/* eslint-disable @typescript-eslint/no-unsafe-return */
import each from 'lodash/each'
import first from 'lodash/first'
import last from 'lodash/last'

export const isNumeric = n => {
  return !isNaN(parseFloat(n)) && isFinite(n)
}

export const bindCallbackToPromise = () => {
  let _resolve
  let _reject

  const promise = () =>
    new Promise((resolve, reject) => {
      _resolve = resolve
      _reject = reject
    })

  const cb = (error, result) => (error ? _reject(error) : _resolve(result))

  return {
    promise,
    cb
  }
}

export const cleanLocation = () => {
  if (cleanUri(window.location.href) !== cleanUri(window.location.origin)) {
    window.location.href = window.location.origin
  }
}

export const cleanUri = uri =>
  uri.endsWith('/') ? uri.substring(0, uri.length - 1) : uri

export const replaceAll = map => obj => {
  each(obj, (v, k) => {
    if (map[k]) {
      obj[k] = map[k]
    } else if (typeof v === 'object') {
      replaceAll(map)(v)
    }
  })
  return obj
}

export const getNameInitials = fullName => {
  if (!fullName) {
    return '??'
  }

  const parts = fullName.split(' ')
  return first(parts)[0] + last(parts)[0]
}
